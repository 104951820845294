// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   headline -> text / Überschrift
//   headline_level -> option
//   logo_items -> bloks
// end of automatic

import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "../technical/BlockWrapper"

const LogoGridBlock = ({ block }) => {
  const sizes = {
    small: "logo-sm",
    normal: "logo-md",
    large: "logo-lg",
  }
  return (
    <BlockWrapper block={block} className="text-white bg-primary">
      <div
        className={`flex flex-row flex-wrap -mx-grid ${
          block.headline?.length > 0 ? "pt-8" : ""
        }`}
      >
        {block.logo_items?.length > 0 &&
          block.logo_items.map(
            (item, index) =>
              item.image?.filename?.length > 0 && (
                <div
                  className="relative w-1/2 md:w-1/3 lg:w-1/4 px-grid"
                  key={index}
                >
                  <div className="flex items-center justify-center w-full h-full py-4 text-center px-grid">
                    <Link
                      className={`block w-full h-auto transition-transform duration-300 transform ${
                        item?.link?.cached_url?.length > 0
                          ? "hover:scale-110"
                          : ""
                      }`}
                      link={item.link}
                    >
                      <Image
                        image={item.image}
                        className={`block w-full h-auto ${
                          sizes[item.logo_size]
                        }`}
                      />
                    </Link>
                  </div>
                </div>
              )
          )}
      </div>
    </BlockWrapper>
  )
}

export default LogoGridBlock
